var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-headings-wrap"},_vm._l((_vm.columns),function(column){return _c('div',{key:column.value,staticClass:"table-headings",style:(column.width
          ? { flexBasis: column.width, minWidth: column.width }
          : { flexGrow: 1 })},[_c('SortHeaderCell',{attrs:{"column":column,"sort":_vm.sort,"onClick":_vm.orderBy}})],1)}),0),_c('div',{class:{ 'table-wrap': true, scrollable: _vm.scrollable }},[_c('div',{staticClass:"table-body"},_vm._l((_vm.columns),function(column,index){return _c('div',{key:index,staticClass:"table-column",style:(column.width
            ? { flexBasis: column.width, minWidth: column.width }
            : { flexGrow: 1 })},_vm._l((_vm.data),function(item,index){return _c('div',{key:column.key ? column.key(item) : index,class:{
            'table-cell': true,
            'active-sort': _vm.sort.property === column.value
          },style:(column.align === 'right'
              ? { justifyContent: 'flex-end' }
              : { justifyContent: 'flex-start' }),on:{"click":function (e) { return (column.value === 'select') ? '' : _vm.onRowClick(item); },"contextmenu":function($event){$event.preventDefault();return (function () { return _vm.onRowClick(item, true); }).apply(null, arguments)}}},[(column.render)?[_vm._v(_vm._s(column.render(item[column.value], item)))]:(column.renderComponent)?[_c(column.renderComponent,{tag:"component",attrs:{"value":item[column.value],"id":index,"data":item}})]:[_vm._v(_vm._s(item[column.value]))]],2)}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }